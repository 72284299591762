import "./App.css";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Hero from "./components/Hero/Hero";
import Products from "./components/Products/Products";
import Slider from "./components/Slider/Slider";
import Testimonial from "./components/Testimonials/Testimonial";
import Visual from "./components/Visual/Visual";

function App() {
  return (
    <div className="App">
      <Header />
      <Hero />
      <Slider />
      <Visual />
      <Products />
      <Testimonial />
      <Footer />
    </div>
  );
}

export default App;
